import {setupListeners} from '@reduxjs/toolkit/query/react';
import {configureStore} from "@reduxjs/toolkit"

import limitedShopReducer from "./LimitedShop/limitedTimeShopSlice"
import divineShopReducer from "./DivineShop/divineShopSlice"
import authV2Reducer from "./AuthV2/AuthV2Slice"
import globalReducer from "./Global/globalSlice"
import lockerReducer from "./Locker/lockerSlice"
import postReducer from "./NewsPosts/postSlice"
import web3Reducer from "./Web3/web3Slice"

import {globalGraphApi, globalMarketServerApi} from "./Global/global.api";
import {lockerApi,lockerMarketApi} from "./Locker/locker.api"
import {limitedShopApi} from "./LimitedShop/limitedShop.api"
import {freeItemApi} from "./FreeItem/freeItem.api"
import {voolahApi} from "./VoolahShop/voolah.api"
import {bazaarApi} from "./Bazaar/bazaar.api"

import {unauthorizedMiddleware} from "../config/unauthorizedMiddleware";

export const store = configureStore({
    reducer: {
        web3: web3Reducer,
        posts: postReducer,
        global: globalReducer,
        authV2: authV2Reducer,
        locker: lockerReducer,
        divineShop: divineShopReducer,
        limitedShop: limitedShopReducer,
        [voolahApi.reducerPath]: voolahApi.reducer,
        [lockerApi.reducerPath]: lockerApi.reducer,
        [bazaarApi.reducerPath]: bazaarApi.reducer,
        [freeItemApi.reducerPath]: freeItemApi.reducer,
        [globalGraphApi.reducerPath]: globalGraphApi.reducer,
        [limitedShopApi.reducerPath]: limitedShopApi.reducer,
        [lockerMarketApi.reducerPath]: lockerMarketApi.reducer,
        [globalMarketServerApi.reducerPath]: globalMarketServerApi.reducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({serializableCheck: false})
            .concat(lockerApi.middleware, unauthorizedMiddleware)
            .concat(bazaarApi.middleware)
            .concat(globalGraphApi.middleware)
            .concat(globalMarketServerApi.middleware)
            .concat(limitedShopApi.middleware)
            .concat(freeItemApi.middleware)
            .concat(lockerMarketApi.middleware,unauthorizedMiddleware)
            .concat(voolahApi.middleware,unauthorizedMiddleware)
})

setupListeners(store.dispatch);