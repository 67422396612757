import {createApi} from "@reduxjs/toolkit/query/react";

import createFetchBaseServerQuery from "config/createFetchBaseServerQuery";
import {ENVIRONMENTS, PROMO_CODE} from "utils";
import {VOOLAH} from "../endpoint";

export const voolahApi = createApi({
    reducerPath: "voolah/api",
    tagTypes: ["PromoCode"],
    baseQuery: createFetchBaseServerQuery({baseUrl: ENVIRONMENTS.MARKET_URL, isAuthorization: true}),
    endpoints: (builder) => ({
        getVoolahPromoCode: builder.query({
            query: () => ({
                url: VOOLAH.GET_PROMO_CODE,
                body: {data: {type: PROMO_CODE.VOOLAH}},
                method: "POST",
            }),
            providesTags: () => ["PromoCode"],
            transformResponse: (response) => response.data,
        }),

        addVoolahPromoCode: builder.mutation({
            query: (data) => ({
                url: VOOLAH.ADD_PROMO_CODE,
                body: {
                    data: {
                        type: PROMO_CODE.VOOLAH,
                        promo_code: data
                    }
                },
                method: "POST",
            }),
            invalidatesTags: () => ["PromoCode"],
        })
    })
})

export const {useGetVoolahPromoCodeQuery, useAddVoolahPromoCodeMutation} = voolahApi;