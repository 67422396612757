import React from "react";
import {useTranslation} from "react-i18next";

import {useWindowSize} from "hooks";

import DropdownItem from "components/atoms/DropdownItem";
import Title from "components/atoms/TitleTemplate";

const HomeSpacs = () => {
    const {width} = useWindowSize()
    const {t} = useTranslation()

    return (
        <section className="home_tech-specs">
            <Title underline={true}>{t("techSpecsTitlePartOne")} <span>{t("techSpecsTitlePartTwo")}</span></Title>
            <div className="home_tech-specs_content container">
                <DropdownItem
                    title={t("techSpecsPcMinimumTitle")}
                    open={width > 991}
                >
                    <div className="home_tech-specs_content_dropdown">
                        <ul>
                            <SpecItem text={t("techSpecsPcMinimumOS")} label={t("operatingSystemTitle")}/>
                            <SpecItem text={t("techSpecsPcMinimumCPU")} label={t("cpuTitle")}/>
                            <SpecItem text={t("techSpecsPcMinimumRAM")} label={t("ramTitle")}/>
                            <SpecItem text={t("techSpecsPcMinimumStorage")} label={t("storageRequiredTitle")}/>
                        </ul>
                        <ul>
                            <SpecItem label={t("directXTitle")} text={t("techSpecsPcMinimumDirectX")}/>
                        </ul>
                    </div>
                </DropdownItem>
                <DropdownItem
                    title={t("techSpecsPcRecommendedTitle")}
                    open={width > 991}
                >
                    <div className="home_tech-specs_content_dropdown">
                        <ul>
                            <SpecItem text={t("techSpecsPcRecommendedOS")} label={t("operatingSystemTitle")}/>
                            <SpecItem text={t("techSpecsPcRecommendedCPU")} label={t("cpuTitle")}/>
                            <SpecItem text={t("techSpecsPcRecommendedRAM")} label={t("ramTitle")}/>
                            <SpecItem text={t("techSpecsPcRecommendedStorage")} label={t("storageRequiredTitle")}/>
                        </ul>
                        <ul>
                            <SpecItem label={t("techSpecsPcRecommendedDirectX")} text={t("techSpecsPcMinimumDirectX")}/>
                        </ul>
                    </div>
                </DropdownItem>
                <DropdownItem
                    title={t("techSpecsIosMinimumTitle")}
                    open={width < 991}
                >
                    <div className="home_tech-specs_content_dropdown">
                        <ul>
                            <SpecItem label={t("operatingSystemTitle")} text={t("techSpecsIosMinimumOS")}/>
                            <SpecItem label={t("ramTitle")} text={t("techSpecsiosMinimumRAM")}/>
                            <SpecItem label={t("storageRequiredTitle")} text={t("techSpecsiosMinimumStorage")}/>
                        </ul>
                        <ul>
                            <SpecItem label={t("deviceTitle")} text={t("techSpecsIosMinimumDevice")}/>
                            <SpecItem label={t("networkTitle")} text={t("techSpecsiosMinimumNetwork")}/>
                        </ul>
                    </div>
                </DropdownItem>
                <DropdownItem
                    title={t("techSpecsAndroidMinimumTitle")}
                    open={width < 991}
                >
                    <div className="home_tech-specs_content_dropdown">
                        <ul>
                            <SpecItem label={t("operatingSystemTitle")} text={t("techSpecsAndroidMinimumOS")}/>
                            <SpecItem label={t("ramTitle")} text={t("techSpecsAndroidMinimumRAM")}/>
                            <SpecItem label={t("cpuTitle")} text={t("techSpecsAndroidMinimumCPU")}/>
                            <SpecItem label={t("storageRequiredTitle")} text={t("techSpecsAndroidMinimumStorage")}/>
                        </ul>
                        <ul>
                            <SpecItem label={t("networkTitle")} text={t("techSpecsAndroidMinimumNetwork")}/>
                        </ul>
                    </div>
                </DropdownItem>
            </div>
        </section>
    )
}
export default HomeSpacs

const SpecItem = ({label, text}) => (
    <li>
        <label>{label}</label>
        <p>{text}</p>
    </li>
)