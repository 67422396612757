import React, {useState} from "react";

import {useWindowSize} from "hooks";

import VideoEmbed from "components/molecules/VideoEmbed";
import DeviceCardBlock from "./DeviceCardBlock";

import "./style.scss"

const HomeMain = () => {
    const {width} = useWindowSize()
    const [videoHeight, setVideoHeight] = useState(0)

    return (
        <section className="home_main">
            <VideoEmbed
                isOverlay={true}
                padding="58% 0 0 0"
                title="Website-Hero-Horiz"
                imgClass="home_main_back-img"
                heightCallBack={(height)=> setVideoHeight(height)}
                src="https://player.vimeo.com/video/1060753357?h=36da56d456&amp;badge=0&amp;player_id=0&amp;app_id=58479"
            />
            {width > 991 && <DeviceCardBlock videoHeight={videoHeight} />}
        </section>
    )
}

export default HomeMain