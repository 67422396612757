import React, {useState, memo, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {CHAINS_ENVIRONMENT, CHAINS_MAP, SUPPORTED_CHAINS} from "utils";
import {useBalance, useConnectedWallet, useUtils} from "hooks";
import {switchCurrentChain} from "store/Web3/web3Slice";

import {Switch} from "components/atoms";

const SwitchBlock = () => {
    const dispatch = useDispatch()
    const {switchChain, getChainDataById, addChain} = useUtils()
    const {getBalanceAndSave, getKMTBalanceAndSave} = useBalance()
    const {connectedChainId, isConnected} = useConnectedWallet()

    const {currentChain} = useSelector(state => state.web3)
    const {userData} = useSelector(state => state.authV2.signIn)

    const supportedChains = CHAINS_MAP[CHAINS_ENVIRONMENT]
    const activeChainId = isConnected ? connectedChainId : currentChain.chainId
    const isChecked = activeChainId === supportedChains.BASE
    const [checked, setChecked] = useState(isChecked)

    useEffect(() => {
        chainCheck()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connectedChainId])

    const switchNetwork = async (checked) => {
        const currentChainId = checked ? supportedChains.BASE : supportedChains.ETH

        setChecked(checked)

        const {chainIdHex, chainId} = getChainDataById(currentChainId)

        const {status, error} = await switchChain(chainIdHex)

        if (!status && error.code === 4902) await addChain(chainId)

        if (!status) setChecked(!checked)
    }

    const chainCheck = async () => {
        if (connectedChainId && SUPPORTED_CHAINS[CHAINS_ENVIRONMENT].includes(connectedChainId)) {
            setChecked(connectedChainId === supportedChains.BASE)

            if (userData?.wallet) {
                getBalanceAndSave(connectedChainId, userData.wallet)
                getKMTBalanceAndSave(connectedChainId, userData.wallet)
            }

            dispatch(switchCurrentChain({chainId: connectedChainId}))
        }
    }

    return (
        <div className="navigation_auth_user-details_panel_switch-block">
            <span className="navigation_auth_user-details_panel_switch-block_chain-name">
                {checked ? "Base" : "Eth"}
            </span>
            <Switch
                checked={checked}
                disabled={!isConnected}
                onChange={() => switchNetwork(!checked)}
            />
        </div>
    )
}

export default memo(SwitchBlock)