import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {formatNumberToEuropeanStyle, GLOBAL_MODALS, voolahPackageDetails} from "utils";
import {useGlobalModalsContext, useModalsContext} from "layouts";
import {userGetVoolahBalance} from "store/AuthV2/AuthV2Slice";
import {useAuthorization, useGMTEvents} from "hooks";
import useVoolahShop from "./useVoolahShop";

import CosmeticViewTemplate from "components/molecules/CosmeticViewTemplate";
import VoolahSteps from "./modals/VoolahSteps";
import {Button, Icon} from "components/atoms";

import "./style.scss"

const VoolahShop = ({widgetIsLoad}) => {
    const {signIn} = useSelector(state => state.authV2)

    const {voolahPurchaseComplete} = useGMTEvents()
    const {setCurrentModal} = useModalsContext()
    const dispatch = useDispatch()
    const {setModal} = useGlobalModalsContext()
    const {isAuth} = useAuthorization()
    const {t} = useTranslation()

    const {
        searchParams,
        XPayWidgetClose,
        setSearchParams,
        XPayEventProcess,
        currentPackageIndex,
        searchParamsChecking,
        setXPayStationWidgetSettings
    } = useVoolahShop()

    useEffect(() => {
        searchParamsChecking()
        // eslint-disable-next-line
    }, [signIn.userData])

    useEffect(() => {
        if (widgetIsLoad) setXPayStationWidgetSettings()
        // eslint-disable-next-line
    }, [widgetIsLoad])

    useEffect(() => {
        if (XPayEventProcess.status === "done") {
            setTimeout(() => {
                setCurrentModal({status: "success"})
                dispatch(userGetVoolahBalance())
            }, 10)
            voolahPurchaseComplete()
        }
        // eslint-disable-next-line
    }, [XPayWidgetClose])

    const chooseVoolah = (index) => {
        searchParams.set("voolah", index)
        setSearchParams(searchParams)
    }

    return (
        <section className="shop-voolah">
            <CosmeticViewTemplate
                classname="shop-voolah_tabs_container"
                title={<>{formatNumberToEuropeanStyle(voolahPackageDetails[currentPackageIndex].value)} <Icon
                    name="voolahLogo"/></>}
                img={voolahPackageDetails[currentPackageIndex].src.big}
            >
                <div className="shop-voolah_tabs_container_choice">
                    <div className="shop-voolah_tabs_container_choice_items">
                        <span className="block-title">{t("selectAmount")}</span>
                        <div className="shop-voolah_tabs_container_choice_items_img">
                            {React.Children.toArray(
                                voolahPackageDetails.map(({id, alt, src}, index) =>
                                    <div
                                        onClick={() => chooseVoolah(index)}
                                        className={+currentPackageIndex === id ? "active" : ""}
                                    >
                                        <img alt={alt} src={src.small}/>
                                    </div>)
                            )}
                        </div>
                    </div>
                    <div className="shop-voolah_tabs_container_price">
                        <span className="block-title">{t("currentPrice")}</span>
                        <div>${voolahPackageDetails[currentPackageIndex].price}</div>
                    </div>
                    <Button
                        onClick={() => isAuth ? setCurrentModal({status: "order"}) : setModal(GLOBAL_MODALS.LOGIN_ALERT)}
                    >
                        {t("buyNow")}
                    </Button>
                    <div className="shop-voolah_tabs_container_description">
                        <span className="block-title">{t("description")}</span>
                        <p>{t(voolahPackageDetails[currentPackageIndex].text)}</p>
                    </div>
                </div>
            </CosmeticViewTemplate>
            <VoolahSteps currentPackageIndex={currentPackageIndex}/>
        </section>
    )
}

export default VoolahShop